// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_app__zr1wm {
	position: relative;
	/* max-width: 1440px; */
	max-width: 2560px;
	margin-left: auto;
	margin-right: auto;
}

.App_fixedButton__yt8sK {
	position: sticky;
	bottom: 13%;
	left: 93%;
	background: none;
	border: none;
	cursor: pointer;
	z-index: 12;
	@media screen and (max-width: 768px) {
		width: 50px;
		height: 50px;
	}
}

.App_fixedImg__cbbTk {
	width: 100%;
	height: 100%;
}

.App_modalContent__M1CT4 {
	/* background-color: white; */
	padding: 20px;
	border-radius: 10px;
	max-width: 1000px;
	margin: auto;
	@media screen and (max-width: 768px) {
		/* margin-left: 20px;
		margin-right: 20px; */
		padding: 0;
		width: fit-content;
		max-height: 93vh;
		overflow-y: auto;
	}
}

.App_modalOverlay__AfBXA {
	background-color: rgba(0, 0, 0, 0.75);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 11;
	display: flex;
	align-items: center;
	justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,uBAAuB;CACvB,iBAAiB;CACjB,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;CAChB,WAAW;CACX,SAAS;CACT,gBAAgB;CAChB,YAAY;CACZ,eAAe;CACf,WAAW;CACX;EACC,WAAW;EACX,YAAY;CACb;AACD;;AAEA;CACC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,6BAA6B;CAC7B,aAAa;CACb,mBAAmB;CACnB,iBAAiB;CACjB,YAAY;CACZ;EACC;uBACqB;EACrB,UAAU;EACV,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;CACjB;AACD;;AAEA;CACC,qCAAqC;CACrC,eAAe;CACf,MAAM;CACN,OAAO;CACP,QAAQ;CACR,SAAS;CACT,WAAW;CACX,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB","sourcesContent":[".app {\n\tposition: relative;\n\t/* max-width: 1440px; */\n\tmax-width: 2560px;\n\tmargin-left: auto;\n\tmargin-right: auto;\n}\n\n.fixedButton {\n\tposition: sticky;\n\tbottom: 13%;\n\tleft: 93%;\n\tbackground: none;\n\tborder: none;\n\tcursor: pointer;\n\tz-index: 12;\n\t@media screen and (max-width: 768px) {\n\t\twidth: 50px;\n\t\theight: 50px;\n\t}\n}\n\n.fixedImg {\n\twidth: 100%;\n\theight: 100%;\n}\n\n.modalContent {\n\t/* background-color: white; */\n\tpadding: 20px;\n\tborder-radius: 10px;\n\tmax-width: 1000px;\n\tmargin: auto;\n\t@media screen and (max-width: 768px) {\n\t\t/* margin-left: 20px;\n\t\tmargin-right: 20px; */\n\t\tpadding: 0;\n\t\twidth: fit-content;\n\t\tmax-height: 93vh;\n\t\toverflow-y: auto;\n\t}\n}\n\n.modalOverlay {\n\tbackground-color: rgba(0, 0, 0, 0.75);\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tz-index: 11;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `App_app__zr1wm`,
	"fixedButton": `App_fixedButton__yt8sK`,
	"fixedImg": `App_fixedImg__cbbTk`,
	"modalContent": `App_modalContent__M1CT4`,
	"modalOverlay": `App_modalOverlay__AfBXA`
};
export default ___CSS_LOADER_EXPORT___;
