// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DomyWelcome_welcomeSection__H5fjW {
	height: 700px;
	position: relative;

	@media screen and (max-width: 768px) {
		height: 160px;
	}
}

.DomyWelcome_welcomeVideo__bcJPt {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/components/DomyWelcome/DomyWelcome.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,kBAAkB;;CAElB;EACC,aAAa;CACd;AACD;;AAEA;CACC,kBAAkB;CAClB,QAAQ;CACR,SAAS;CACT,WAAW;CACX,YAAY;CACZ,iBAAiB;CACjB,gCAAgC;AACjC","sourcesContent":[".welcomeSection {\n\theight: 700px;\n\tposition: relative;\n\n\t@media screen and (max-width: 768px) {\n\t\theight: 160px;\n\t}\n}\n\n.welcomeVideo {\n\tposition: absolute;\n\ttop: 50%;\n\tleft: 50%;\n\twidth: 100%;\n\theight: 100%;\n\tobject-fit: cover;\n\ttransform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"welcomeSection": `DomyWelcome_welcomeSection__H5fjW`,
	"welcomeVideo": `DomyWelcome_welcomeVideo__bcJPt`
};
export default ___CSS_LOADER_EXPORT___;
